<template>
  <div class="col-auto">
    <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="goBack">
      <template v-slot:iconStart>
        <IconArrowBack color="white" />
      </template>
    </Button>
  </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'
export default {
  name: 'BackButtonFooter',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue'))
  },
  emits: ['goBack'],
  methods: {
    goBack () {
      this.$emit('goBack')
    }
  }
}
</script>
